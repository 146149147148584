import * as React from 'react';

function Illustration(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg fill="none" viewBox="0 0 748 551" {...props}>
      <path
        fill="#f27083"
        d="M736.2 550.4H262.6c-6.5 0-11.8-5.3-11.8-11.8V230c0-6.5 5.3-11.8 11.8-11.8h473.6c6.5 0 11.8 5.3 11.8 11.8v308.8c0 6.4-5.3 11.6-11.8 11.6z"
      />
      <path
        fill="#fff"
        d="M262.6 541.2c-2.5 0-4.6-2.1-4.6-4.6V264c0-2.5 2.1-4.6 4.6-4.6h473.6c2.5 0 4.6 2.1 4.6 4.6v272.6c0 2.5-2.1 4.6-4.6 4.6H262.6z"
      />
      <path
        fill="#fcd63a"
        d="M682 233.4c-3.5 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c3.5 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5zm19.8 0c-3.5 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-3-6.5-6.5-6.5zm19.7 0c-3.5 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c3.5 0 6.5-2.9 6.5-6.5s-3-6.5-6.5-6.5zm-449.4 20.5c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1c-.1 7.8-6.4 14.1-14.1 14.1zm184.5-6.4H300.3c-1.3 0-2.3-1-2.3-2.3v-10.8c0-1.3 1-2.3 2.3-2.3h156.3c1.3 0 2.3 1 2.3 2.3v10.8c.1 1.3-1 2.3-2.3 2.3z"
      />
      <path
        fill="#f27083"
        d="M315 318.6h-34.7c-3.3 0-5.9-2.6-5.9-6V278c0-3.3 2.6-6 5.9-6H315c3.3 0 6 2.6 6 6v34.7c-.1 3.2-2.8 5.9-6 5.9z"
      />
      <path
        fill="#209ebd"
        d="M619.1 333.1H348.8c-2.2 0-3.9-1.7-3.9-3.9v-53.4c0-2.2 1.7-3.9 3.9-3.9h270.1c2.2 0 3.9 1.7 3.9 3.9v53.4c.2 2.1-1.6 3.9-3.7 3.9z"
      />
      <path
        fill="#f27083"
        d="M650.4 405.4h72.1c2.2 0 3.9-1.7 3.9-3.9V275.8c0-2.2-1.7-3.9-3.9-3.9h-72.1c-2.2 0-3.9 1.7-3.9 3.9v125.8c0 2.1 1.7 3.8 3.9 3.8z"
      />
      <path
        fill="#6956f5"
        d="M650.4 521.9h72.1c2.2 0 3.9-1.7 3.9-3.9v-97.6c0-2.2-1.7-3.9-3.9-3.9h-72.1c-2.2 0-3.9 1.7-3.9 3.9V518c0 2.2 1.7 3.9 3.9 3.9z"
      />
      <path
        fill="#d2d5ff"
        d="M654.9 460.6v-31.4c0-3 2.4-5.5 5.5-5.5h52.3c3 0 5.5 2.4 5.5 5.5v31.4c0 3-2.4 5.5-5.5 5.5h-52.3c-3.1 0-5.5-2.5-5.5-5.5z"
      />
      <path
        fill="#fcd63a"
        d="M619.1 405.4H348.8c-2.2 0-3.9-1.7-3.9-3.9v-53.4c0-2.2 1.7-3.9 3.9-3.9h270.1c2.2 0 3.9 1.7 3.9 3.9v53.4c.2 2.2-1.6 3.9-3.7 3.9z"
      />
      <path
        fill="#f27083"
        d="M619.1 521.9H348.8c-2.2 0-3.9-1.7-3.9-3.9v-97.6c0-2.2 1.7-3.9 3.9-3.9h270.1c2.2 0 3.9 1.7 3.9 3.9V518c.2 2.2-1.6 3.9-3.7 3.9z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 354.8h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.8-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 349.2h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8s-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 375.8h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.8-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 370.2h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8.1 1-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 396.8h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.7-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 391.1h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8s-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 439h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.8-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 433.4h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8.1 1-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 459.9h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.9-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 454.3h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8s-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fcd63a"
        d="M285.8 480.9h-8.1c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h8.1c1.8 0 3.3 1.5 3.3 3.3v8.1c0 1.8-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#d2d5ff"
        d="M328.9 475.3h-32.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8h32.6c1 0 1.8.8 1.8 1.8-.1 1-.9 1.8-1.8 1.8z"
      />
      <path
        fill="#fff"
        d="M670.7 318.3h-11.8c-3 0-5.5-2.4-5.5-5.5V301c0-3 2.4-5.5 5.5-5.5h11.8c3 0 5.5 2.4 5.5 5.5v11.8c0 3.1-2.5 5.5-5.5 5.5zm27.3-30.6h-43.1c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8H698c1 0 1.8.8 1.8 1.8 0 .9-.8 1.8-1.8 1.8zm19.5 205.7h-62.1c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h62.1c.5 0 .9.5.9.9s-.4.9-.9.9zm0 5.3h-62.1c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h62.1c.5 0 .9.5.9.9s-.4.9-.9.9zm-17.8 5.2h-44.4c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h44.4c.5 0 .9.5.9.9 0 .6-.3.9-.9.9zM681.8 299H718c.5 0 .9-.5.9-.9 0-.5-.5-.9-.9-.9h-36.3c-.5 0-.9.5-.9.9 0 .5.5.9 1 .9zm36.2 4.4h-36.3c-.5 0-.9.5-.9.9 0 .5.5.9.9.9H718c.5 0 .9-.5.9-.9-.1-.6-.4-.9-.9-.9zm-18.3 6.1h-18c-.5 0-.9.5-.9.9s.5.9.9.9h18c.5 0 .9-.5.9-.9s-.3-.9-.9-.9zm-29 38.8h-11.8c-3 0-5.5-2.4-5.5-5.5V331c0-3 2.4-5.5 5.5-5.5h11.8c3 0 5.5 2.4 5.5 5.5v11.8c0 3.1-2.5 5.5-5.5 5.5zM659 364.5c-3.1 0-5.6 2.5-5.6 5.6 0 3.1 2.5 5.6 5.6 5.6 3.1 0 5.6-2.5 5.6-5.6 0-3.1-2.5-5.6-5.6-5.6zm59 1.6h-49.7c-.5 0-.9.5-.9.9 0 .5.5.9.9.9H718c.5 0 .9-.5.9-.9-.1-.5-.4-.9-.9-.9zm-27.8 6.1h-21.9c-.5 0-.9.5-.9.9 0 .5.5.9.9.9h21.9c.5 0 .9-.5.9-.9s-.4-.9-.9-.9zm-31.2 9.9c-3.1 0-5.6 2.5-5.6 5.6 0 3.1 2.5 5.6 5.6 5.6 3.1 0 5.6-2.5 5.6-5.6 0-3.2-2.5-5.6-5.6-5.6zm59 1.5h-49.7c-.5 0-.9.5-.9.9 0 .5.5.9.9.9H718c.5 0 .9-.5.9-.9 0-.5-.4-.9-.9-.9zm-27.8 6.1h-21.9c-.5 0-.9.5-.9.9s.5.9.9.9h21.9c.5 0 .9-.5.9-.9s-.4-.9-.9-.9zm-8.4-60.7H718c.5 0 .9-.5.9-.9s-.5-.9-.9-.9h-36.3c-.5 0-.9.5-.9.9s.5.9 1 .9zm36.2 4.4h-36.3c-.5 0-.9.5-.9.9s.5.9.9.9H718c.5 0 .9-.5.9-.9s-.4-.9-.9-.9zm-18.3 6.1h-18c-.5 0-.9.5-.9.9 0 .5.5.9.9.9h18c.5 0 .9-.5.9-.9 0-.5-.3-.9-.9-.9z"
      />
      <path
        fill="url(#paint0_linear)"
        d="M636 158.5l26.4-109.3c.3-1.4-.7-2.6-2.1-2.6-.5 0-.9.1-1.4.5L523.2 158.5H636z"
      />
      <path
        fill="#29427b"
        d="M667.5 43.1l6.2 11v9.3c0 .3.2.6.6.6.2 0 .6-.2.6-.5l1.4-9.4-5.1-11h-3.7zm-26.1 11l1.4 9.4c0 .2.2.5.6.5.3 0 .6-.2.6-.6v-9.3l6.2-11h-3.4l-5.4 11zm19.3-7.2c4.5-1.1 7.3-5.6 6.2-10.1-1.1-4.5-5.6-7.3-10-6.2-4.5 1.1-7.3 5.6-6.2 10.1 1.1 4.5 5.5 7.3 10 6.2z"
      />
      <path
        fill="#fff"
        d="M658.8 33c-2.6 0-5 1-6.6 2.6 1.1-2.5 3.8-4.2 6.6-4.2 2.9 0 5.5 1.7 6.6 4.2-1.6-1.6-4-2.6-6.6-2.6z"
      />
      <path
        fill="#ddd9ff"
        d="M647.3 50.3h22.8c.7 0 1.3-.6 1.3-1.3v-8.9c0-.7-.6-1.3-1.3-1.3h-22.8c-.7 0-1.3.6-1.3 1.3V49c.1.7.6 1.3 1.3 1.3z"
      />
      <path
        fill="#29427b"
        d="M690.9 41.5h-19.6v4.4l17.7-2.2c1.1-.3 1.9-1.1 1.9-2.2z"
      />
      <path
        fill="#a8aeff"
        d="M687.9 35.7h1.7c.5 0 .8.3.8.8l.5 5h-4.2l.5-5c-.1-.5.3-.8.7-.8z"
      />
      <path
        fill="#0a2b39"
        d="M689.7 35.7H688c0-.5 0-.9.1-1.4.1-.8 1.3-.8 1.4 0 .2.4.2.9.2 1.4z"
      />
      <path
        fill="#ddd9ff"
        d="M689.4 35l16.7.6c0-.9-.6-1.7-1.4-1.7l-15.3.6v.5zm-1.2-.4l-16.7-.6c0 .9.6 1.7 1.4 1.7l15.3-.6v-.5z"
      />
      <path
        fill="#29427b"
        d="M626.5 41.5h19.6v4.4l-17.7-2.3c-1.1-.2-1.9-1-1.9-2.1z"
      />
      <path
        fill="#a8aeff"
        d="M629.6 35.7h-1.7c-.5 0-.8.3-.8.8l-.5 5h4.2l-.5-5c.1-.5-.4-.8-.7-.8z"
      />
      <path
        fill="#0a2b39"
        d="M627.9 35.7h1.7c0-.5 0-.9-.1-1.4-.1-.8-1.3-.8-1.4 0-.2.4-.2.9-.2 1.4z"
      />
      <path
        fill="#ddd9ff"
        d="M628 35l-16.7.6c0-.9.6-1.7 1.4-1.7l15.3.6v.5zm1.2-.4l16.7-.6c0 .9-.6 1.7-1.4 1.7l-15.3-.6v-.5z"
      />
      <path
        fill="#0a2b39"
        d="M654.5 50c0 2.3 1.8 4.2 4.2 4.2 2.3 0 4.2-1.8 4.2-4.2 0-2.3-1.8-4.2-4.2-4.2-2.2 0-4.2 2-4.2 4.2z"
      />
      <path
        fill="#ddd9ff"
        d="M658.8 52.1c1.1 0 2.1-.9 2.1-2.1 0-1.1-.9-2.1-2.1-2.1-1.1 0-2.1.9-2.1 2.1 0 1.2.9 2.1 2.1 2.1z"
      />
      <path
        fill="#fcd63a"
        d="M235.9 550.4h-59.7c-1.6 0-2.3-1.3-1.6-2.7l9.5-21.9c.3-.7 1.4-1.3 2.4-1.3h39.2c1 0 2.1.6 2.4 1.3l9.5 21.9c.6 1.5-.1 2.7-1.7 2.7z"
      />
      <path
        fill="#fcd63a"
        d="M219.5 518.5h-26.9c-.8 0-1.4.6-1.4 1.4v3.4c0 .8.6 1.4 1.4 1.4h26.9c.8 0 1.4-.6 1.4-1.4v-3.4c0-.7-.5-1.4-1.4-1.4z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 145.2c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 145.2c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 145c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 164.8c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 164.8c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 164.6c-.2.2-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 184.5c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 184.5c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 184.3c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 204.1c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 204.1c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-19.7 19.7c-.1.2-.3.2-.6.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 223.8c-.2 0-.5-.1-.6-.2l-19.4-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.5.1-.7.2-.9.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 223.8c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 223.6c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 243.5c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 243.5c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 243.3c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 263.1c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 263.1c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 262.8c-.2.2-.4.3-.7.3z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 282.8c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 282.8c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 282.5c-.2.2-.4.3-.7.3z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 302.4c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 302.4c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 302.1c-.2.3-.4.3-.7.3z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 322.1c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3 0-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 322.1c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-19.7 19.7c-.1 0-.3.2-.6.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 341.6c-.2 0-.5-.1-.6-.2l-19.4-19.6c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.5.1-.7.1-.9.1z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 341.6c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 341.4c-.2.2-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 361.3c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 361.3c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 361.1c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 380.9c-.2 0-.5-.1-.6-.2L195.6 361c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 380.9c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 380.7c-.2.2-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 400.6c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 400.6c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 400.4c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 420.2c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 420.2c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 420c-.2.2-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 439.9c-.2 0-.5-.1-.6-.2L195.6 420c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 439.9c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 439.7c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 459.6c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 459.6c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-19.7 19.6c-.1.2-.3.3-.6.3z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 479.2c-.2 0-.5-.1-.6-.2l-19.4-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.5.1-.7.2-.9.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 479.2c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 479c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 498.9c-.2 0-.5-.1-.6-.2L195.6 479c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 498.9c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 498.7c-.2.1-.4.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M215.8 518.5c-.2 0-.5-.1-.6-.2l-19.6-19.7c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l19.6 19.7c.3.3.3.9 0 1.3-.3.2-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M196.3 518.5c-.2 0-.5-.1-.6-.2-.3-.3-.3-.9 0-1.3l19.6-19.7c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L197 518.2c-.2.3-.4.3-.7.3z"
      />
      <path
        fill="#fcd63a"
        d="M216.2 518.5h-20.4c-.5 0-.9-.5-.9-.9V124.7c0-.5.5-.9.9-.9h20.4c.5 0 .9.5.9.9v392.8c0 .6-.4 1-.9 1zm-19.4-1.9h18.5v-391h-18.5v391z"
      />
      <path
        fill="#fff3c1"
        d="M206 62.4c-.5 0-.9-.5-.9-.9V.9c0-.5.5-.9.9-.9.5 0 .9.5.9.9v60.6c.1.6-.3.9-.9.9z"
      />
      <path
        fill="#fff3c1"
        d="M215.3 54.2h-18.5c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h18.5c.5 0 .9.5.9.9s-.4.9-.9.9zm-.9-8.8h-16.7c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h16.7c.5 0 .9.5.9.9 0 .5-.3.9-.9.9zm-.9-8.8h-14.9c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h14.9c.5 0 .9.5.9.9 0 .6-.3.9-.9.9zm-.9-8.7h-13c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h13c.5 0 .9.5.9.9 0 .6-.4.9-.9.9zm-.9-8.7h-11.3c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h11.3c.5 0 .9.5.9.9 0 .5-.4.9-.9.9zm-.8-8.7h-9.5c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h9.5c.5 0 .9.5.9.9-.1.5-.5.9-.9.9z"
      />
      <path
        fill="#fcd63a"
        d="M209.9 1.8h-7.7c-.5 0-.9-.5-.9-.9 0-.5.5-.9.9-.9h7.7c.5 0 .9.5.9.9.1.5-.4.9-.9.9z"
      />
      <path
        fill="#fff3c1"
        d="M189 62.9c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.4.9-.9.9zm-7 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.3.9-.9.9zm-6.8 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.5.9-.9.9zm-6.9 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c-.1.4-.4.9-.9.9zm-7 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.4.9-.9.9zm-6.8 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c-.1.4-.5.9-.9.9zm-7 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9s.9.5.9.9V62c0 .4-.5.9-.9.9zm-6.9 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.4.9-.9.9zm-7 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.3.9-.9.9zm-6.8 0c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.5.9-.9.9z"
      />
      <path
        fill="#fcd63a"
        d="M119.8 62.9c-.5 0-.9-.5-.9-.9v-8.7c0-.5.5-.9.9-.9.5 0 .9.5.9.9V62c0 .4-.3.9-.9.9z"
      />
      <path
        fill="#fcd63a"
        d="M119.8 54.2c-.3 0-.6-.1-.8-.5-.2-.5-.1-1 .2-1.3l82.4-52c.5-.2 1-.1 1.3.2.2.5.1 1-.2 1.3l-82.4 52c-.2.3-.3.3-.5.3z"
      />
      <path
        fill="#fcd63a"
        d="M140.6 54.2c-.2 0-.5-.1-.7-.3-.3-.3-.2-.9.1-1.3l61.7-52c.3-.3.9-.2 1.3.1.3.3.2.9-.1 1.3l-61.7 52c-.2.1-.5.2-.6.2zm222.6-7.7h-.2L209.7 2.2c-.5-.1-.8-.7-.6-1.1.1-.5.7-.8 1.1-.6l153.3 44.3c.5.1.8.7.6 1.1-.2.4-.5.6-.9.6z"
      />
      <path
        fill="#fff3c1"
        d="M248.8 62.9c-.2 0-.5-.1-.7-.2l-16.4-16.4c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.1.1-.3.2-.6.2z"
      />
      <path
        fill="#fff3c1"
        d="M281.6 62.9c-.2 0-.5-.1-.7-.2L265.2 47l-15.7 15.7c-.3.3-.9.3-1.3 0-.3-.3-.3-.9 0-1.3L264.6 45c.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M314.2 62.9c-.2 0-.5-.1-.7-.2L297.8 47l-15.7 15.7c-.3.3-.9.3-1.3 0-.3-.3-.3-.9 0-1.3L297.2 45c.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.3.1-.5.2-.7.2z"
      />
      <path
        fill="#fff3c1"
        d="M346.9 62.9c-.2 0-.5-.1-.7-.2L330.5 47l-15.7 15.7c-.3.3-.9.3-1.3 0-.3-.3-.3-.9 0-1.3L330 45c.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.4.1-.6.2-.8.2z"
      />
      <path
        fill="#fff3c1"
        d="M379.5 62.9c-.2 0-.5-.1-.7-.2L363.1 47l-15.7 15.7c-.3.3-.9.3-1.3 0-.3-.3-.3-.9 0-1.3L362.6 45c.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.4.1-.6.2-.8.2z"
      />
      <path
        fill="#fff3c1"
        d="M379.5 62.9c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3L395.2 45c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-16.4 16.4c-.2.1-.4.2-.6.2z"
      />
      <path
        fill="#fcd63a"
        d="M395.9 46.5H232.6c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h163.3c.5 0 .9.5.9.9s-.5.9-.9.9zm-200 7.7h-76.1c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h76.1c.5 0 .9.5.9.9s-.4.9-.9.9zm0 8.7h-76.1c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h76.1c.5 0 .9.5.9.9s-.4.9-.9.9z"
      />
      <path
        fill="#fcd63a"
        d="M412.2 62.9H180.5c-.5 0-.9-.5-.9-.9s.5-.9.9-.9h231.7c.5 0 .9.5.9.9s-.4.9-.9.9z"
      />
      <path
        fill="#fcd63a"
        d="M216.2 62.9c-.5 0-.8-.3-.9-.8L209 1c0-.5.3-.9.8-1 .5 0 .9.3 1 .8l6.3 61c0 .5-.3 1-.9 1.1zm-20.3 0c-.6 0-.9-.5-.9-1l6.4-61c0-.5.5-.9 1-.8.5 0 .8.5.8 1l-6.4 61c0 .4-.5.8-.9.8zm216.3 0c-.2 0-.5-.1-.7-.2l-16.4-16.4c-.3-.3-.3-.9 0-1.3.3-.3.9-.3 1.3 0l16.4 16.4c.3.3.3.9 0 1.3-.1.1-.4.2-.6.2z"
      />
      <path
        fill="#fcd63a"
        d="M216.2 62.9c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3L231.9 45c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-16.4 16.4c-.1.1-.3.2-.6.2z"
      />
      <path
        fill="#fcd63a"
        d="M219.8 101.1h-27.5c-3.2 0-5.8-2.6-5.8-5.8V67.8c0-3.2 2.6-5.8 5.8-5.8h18.2c8.4 0 15.1 6.8 15.1 15.1v18.2c0 3.2-2.6 5.8-5.8 5.8z"
      />
      <path
        fill="#f27083"
        d="M222.1 77.1c0-6.3-5.1-11.5-11.4-11.5h-18.2c-1.3 0-2.3 1-2.3 2.3v16.7h31.9v-7.5z"
      />
      <path
        fill="#fff3c1"
        d="M224.2 113h-36.4v10.8h36.4V113zm-8.4-11.9h-19.3v6.1h19.3v-6.1z"
      />
      <path fill="#fff3c1" d="M215.8 101.1h-19.3v6.1h19.3v-6.1z" />
      <path
        fill="#fcd63a"
        d="M224.2 113h-36.4l8.6-5.8h19.3l8.5 5.8zm118.9 164.2c-.2 0-.5-.2-.5-.5V66.8c0-.2.2-.5.5-.5.2 0 .5.2.5.5v209.8c0 .3-.3.6-.5.6zm9 0c-.2 0-.5-.2-.5-.5V66.8c0-.2.2-.5.5-.5s.5.2.5.5v209.8c0 .3-.2.6-.5.6z"
      />
      <path
        fill="#fcd63a"
        d="M360.3 66.8h-25.2c-2.6 0-4.8-2.2-4.8-4.8 0-2.6 2.2-4.8 4.8-4.8h25.2c2.6 0 4.8 2.2 4.8 4.8 0 2.6-2.2 4.8-4.8 4.8z"
      />
      <path
        fill="#0f6b82"
        d="M347.9 308.2c-2.9 0-5.1-2.3-5.1-5.2 0-1.9 1-3.7 2.7-4.6.7-.3 1-1 1-1.8s.6-1.4 1.4-1.4c.8 0 1.4.6 1.4 1.4 0 1.8-.9 3.4-2.5 4.2-.8.5-1.3 1.3-1.3 2.2 0 1.4 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4 0 2.8-2.3 5.2-5.2 5.2z"
      />
      <path
        fill="#fff3c1"
        d="M360.8 321.8c-.1 0-.2-.1-.3-.2l-12.8-16.4-12.8 16.4c-.1.2-.5.2-.7.1-.2-.1-.2-.5-.1-.7l13.2-16.8c.1-.2.6-.2.7 0l13.2 16.8c.1.2.1.5-.1.7l-.3.1z"
      />
      <path
        fill="#0f6b82"
        d="M351.1 296.5h-7c-1.6 0-3-1.1-3.3-2.7l-2.4-13.2c-.3-2.1 1.3-3.9 3.3-3.9h11.8c2.1 0 3.7 1.9 3.3 3.9l-2.4 13.2c-.2 1.6-1.7 2.7-3.3 2.7z"
      />
      <path
        fill="#f27083"
        d="M385.2 370.4H310c-1.8 0-3.3-1.5-3.3-3.3v-42.4c0-1.8 1.5-3.3 3.3-3.3h75.2c1.8 0 3.3 1.5 3.3 3.3v42.4c0 1.8-1.5 3.3-3.3 3.3z"
      />
      <path
        fill="#fff"
        d="M332.1 334.4h-11.8c-3 0-5.5 2.4-5.5 5.5v11.8c0 3 2.4 5.5 5.5 5.5h11.8c3 0 5.5-2.4 5.5-5.5v-11.8c0-3-2.4-5.5-5.5-5.5zm11.1 3.5h36.3c.5 0 .9-.5.9-.9s-.5-.9-.9-.9h-36.3c-.5 0-.9.5-.9.9 0 .5.5.9.9.9zm18 10.5h-18c-.5 0-.9.5-.9.9s.5.9.9.9h18c.5 0 .9-.5.9-.9 0-.6-.3-.9-.9-.9zm18.3-6.2h-36.3c-.5 0-.9.5-.9.9 0 .5.5.9.9.9h36.3c.5 0 .9-.5.9-.9-.1-.4-.5-.9-.9-.9z"
      />
      <path
        fill="#fcd63a"
        d="M160.1 90.5h-39.2c-1 0-1.8-.8-1.8-1.8V66.4c0-1 .8-1.8 1.8-1.8h39.2c1 0 1.8.8 1.8 1.8v22.1c0 1.1-.8 2-1.8 2z"
      />
      <path
        fill="#f38ca0"
        d="M503.5 493.6c0 3.8.1 7.6.2 11.5 0 1.9.1 3.8.2 5.7l.2 5.7v.6l-.9 6.8c-.3 2.3-.6 4.6-.9 6.8-.6 4.6-1.1 9-1.6 13.6-.1.8-.8 1.3-1.5 1.3-.7-.1-1.3-.7-1.3-1.4.1-4.6.2-9.2.3-13.7 0-2.3 0-4.6.1-6.9v-6.9.6l-1.1-5.6c-.3-1.8-.8-3.7-1.1-5.6-.8-3.7-1.6-7.4-2.5-11.1-.7-2.7 1-5.4 3.7-6.1 2.7-.7 5.4 1 6.1 3.7.1.3.1.7.1 1z"
      />
      <path
        fill="#f38ca0"
        d="M495.3 494.5c-.6 4.2-1 8.5-1.6 12.7-.5 4.2-1 8.5-1.1 12.7-.2 4.2-.5 8.5-.6 12.7-.2 4.2-.3 8.5-.5 12.7 0 .8-.7 1.4-1.4 1.4s-1.3-.6-1.4-1.4c-.2-4.2-.5-8.5-.7-12.7-.2-4.2-.6-8.5-.8-12.7l-.9-12.7c-.3-4.2-.7-8.5-1.1-12.7-.3-2.7 1.7-5.3 4.5-5.5 2.7-.3 5.3 1.7 5.5 4.5.2.2.2.7.1 1z"
      />
      <path
        fill="#c04c85"
        d="M504.4 512.1h-19.1c-2.9-13.2-2.3-23.4 2.2-31.4h13.3c2.7 7.7 4.4 17.8 3.6 31.4z"
      />
      <path
        fill="#686ddf"
        d="M498.1 550.4h1l.3-4.4 1.9 4.4h6.2l.1-1c.1-1.9-2.9-2.3-4.8-2.3l-4.7-5.7c-1.8 2.3 0 5 0 9zm-9.6 0h5s.6-1.4.1-3.2c-1.4-.1-1.3-3.8-1.9-5.8h-3.1c-.6 3-.6 5.9-.1 9z"
      />
      <path
        fill="#f38ca0"
        d="M498.1 541.4l1.8-.9 2.9 6.6-4.7-5.7zm-9.3 0l.5 5.8h2.9l-.3-5.8c-.2-.4-3.1-.4-3.1 0z"
      />
      <path
        fill="#29427b"
        d="M512.9 453c-.3-1.4-1.7-2.2-3.1-1.8-2.5.6-5 1.3-7.4 1.9l-4.9 1.4c-1.6.5-3.2.9-4.8 1.5-1.1.3-2.1 1.6-1.8 2.9.2 1.4 1.7 2.3 3 1.9 1.6-.3 3.3-.8 4.9-1.3l4.9-1.4c2.5-.7 4.9-1.4 7.3-2.2 1.4-.2 2.2-1.6 1.9-2.9z"
      />
      <path
        fill="#29427b"
        d="M522.4 434c-.6-.3-1.3-.1-1.7.5-1.3 1.6-2.3 3.3-3.5 4.9l-3.4 5c-1.5 2.2-3.8 5.7-5.3 8-.7 1.1-.3 2.6.8 3.4 1.1.7 2.7.3 3.4-.9 1.4-2.4 3.4-6.1 4.7-8.4l2.9-5.4c.9-1.8 1.9-3.5 2.7-5.4.3-.6.1-1.4-.6-1.7z"
      />
      <path
        fill="#f38ca0"
        d="M519.3 434.1l1.6-2.4c.2-.3.5-.6.9-.6l2.2-.5c1-.2 1.9.8 1.6 1.7l-1.3 4c-.6 1.7-2.6 2.5-4.1 1.5-1.4-.8-1.7-2.5-.9-3.7z"
      />
      <path
        fill="#29427b"
        d="M500.8 480.7l2.2-24.4c0-1.6-1.5-2.9-3.3-2.9h-13c-1.8 0-3.3 1.3-3.3 2.9l4.1 24.4h13.3z"
      />
      <path
        fill="#fff"
        d="M582.5 438.3l-55.4 55.4c-1.9 1.9-5 1.9-7 0l-23.5-23.5c-1.9-1.9-1.9-5 0-7l55.4-55.4c1.9-1.9 5-1.9 7 0l23.5 23.5c1.9 2 1.9 5.1 0 7z"
      />
      <path
        fill="#a8aeff"
        d="M531.4 468.8l-8.4 8.4c-2.2 2.2-5.6 2.2-7.7 0l-8.4-8.4c-2.2-2.2-2.2-5.6 0-7.7l8.4-8.4c2.2-2.2 5.6-2.2 7.7 0l8.4 8.4c2.1 2.1 2.1 5.5 0 7.7z"
      />
      <path
        fill="#d2ccff"
        d="M526.7 446.8c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-28.8 29c-.2.2-.4.2-.7.2zm3.5 3.5c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3L530.9 450c-.3.2-.5.3-.7.3zm3.4 3.5c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-28.8 28.9c-.2.1-.5.3-.7.3zm3.5 3.4c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-28.9 29c-.1.1-.3.2-.6.2zm3.5 3.4c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-28.9 29c-.2.1-.4.2-.6.2zm3.4 3.5c-.2 0-.5-.1-.7-.2-.3-.3-.3-.9 0-1.3l28.9-29c.3-.3.9-.3 1.3 0 .3.3.3.9 0 1.3l-28.9 29c-.1.1-.4.2-.6.2z"
      />
      <path
        fill="#fff"
        d="M492.9 457.5h5.4c.8 0 1.4-.5 1.4-1.1v-3.1h-11.3c0 .1.1.2.2.2l2.2 2.9c.4.7 1.3 1.1 2.1 1.1z"
      />
      <path
        fill="#29427b"
        d="M487.7 456.3l5.4 18.8-2.5-1.8 14.5.2c.8 0 1.4.6 1.4 1.4 0 .7-.6 1.3-1.1 1.3l-14.4 2.1c-1.1.1-2.2-.6-2.5-1.6v-.2l-5.4-18.8c-.3-1.4.3-2.7 1.7-3.1 1.2-.4 2.6.3 2.9 1.7z"
      />
      <path
        fill="#6a3a14"
        d="M495.2 436.4v15.3h9.4c-5.4-5.6.9-14-5.5-19l-3.9 3.7z"
      />
      <path
        fill="#f38ca0"
        d="M506.4 476l2.4-1.5c.3-.2.5-.5.6-.8l.5-2.1c.2-1-.7-1.8-1.7-1.6l-4 1.1c-1.7.5-2.4 2.5-1.5 4 .8 1.3 2.4 1.7 3.7.9zm-14.2-22.7h3.4v-5.4l-4.2-.6.8 6z"
      />
      <path
        fill="#f38ca0"
        d="M499.6 437.4c0-2.4-1.7-3.8-5.3-3.8-3.5 0-5.3 1.4-5.3 3.8 0 1.9-.3 5.4-.2 7.3.1 2.1.8 3.4 2.4 4.8 1 .8 2.1 1.3 3.1 1.3s2.2-.3 3.1-1.3c1.5-1.3 2.2-2.6 2.4-4.8.3-1.9-.2-5.4-.2-7.3z"
      />
      <path
        fill="#6a3a14"
        d="M491.5 430.2c1.5-.5 4.8-.3 6.3.6 3.1 1.9 3.5 6.5-5.7 6.5-1 .9-1.7 3.8-1.7 6.2H489c-.8-2.6-1.1-6.6-.6-9.7.4-1.8 1.5-3.1 3.1-3.6z"
      />
      <path
        fill="#6a3a14"
        d="M485.8 451.6h7.9c-7.4-7 3.9-20.7-2.7-20.7-5.9 0-8.4 11.9-5.2 20.7z"
      />
      <path
        fill="#f38ca0"
        d="M487.8 440.9c-.5 0-.7.9-.5 1.9.1.5.3.9.6 1.3l.7-2.6c-.2-.3-.4-.6-.8-.6z"
      />
      <path
        fill="#49341f"
        d="M493.7 441.3h-1.9c-.1 0-.3-.1-.3-.3 0-.1.1-.3.3-.3h1.9c.1 0 .3.1.3.3 0 .2-.1.3-.3.3zm5.3 0h-1.9c-.1 0-.3-.1-.3-.3 0-.1.1-.3.3-.3h1.9c.1 0 .3.1.3.3.1.2 0 .3-.3.3zm-6.1 2.3c.3 0 .5-.4.5-.9s-.2-.9-.5-.9-.5.4-.5.9.2.9.5.9zm5.1-1.9c-.2 0-.5.5-.5.9s.2.9.5.9c.2 0 .5-.5.5-.9s-.3-.9-.5-.9z"
      />
      <path
        fill="#d96c81"
        d="M496.2 442.8c.1.8.2 1.6.7 2.2.1.1.1.3-.1.5h-.2l-1 .1c-.2 0-.6-.1-.6-.5 0-.2.1-.6.5-.6h.1l1 .1-.3.5c-.3-.7-.2-1.5-.1-2.3z"
      />
      <path fill="#fff" d="M497.3 446.8c-.7 1.5-3.2 1.5-3.9 0h3.9z" />
      <path
        fill="#6a3a14"
        d="M492.9 439c2.7-1 4.8-2.4 5.6-4.5l.9.8c-.8 2.3-3.3 3.3-6.5 3.7z"
      />
      <path
        fill="#8ed1fe"
        d="M583.5 218.8h-12.4c-1.4 0-2.4-1.1-2.4-2.5v-7.8h17.2v7.8c0 1.3-1 2.5-2.4 2.5zm-32.2 24.9c-.3.5-.7.8-.9 1-1.6.8-3.8.8-4.1 2.1l-.1.9h9.5l-.2-3.2-4.2-.8z"
      />
      <path
        fill="#f38ca0"
        d="M555.2 242.5h-3.1s-.5.7-.9 1.4c1.7 1.9 3.1 2.2 4.1.8l-.1-2.2z"
      />
      <path
        fill="#8ed1fe"
        d="M572.2 244.1l-.3.6c-1.1.6-2.2 1.3-2.2 2.2v.9h6.5l-.1-3.7h-3.9zm.8-34.1l-16.4 4-.3.1c-1.3.3-2.1 1.4-2.2 2.5l-2.4 26.2 3.8.6 4.7-25.9-2.6 2.6 16.8-1.4-1.4-8.7z"
      />
      <path
        fill="#f38ca0"
        d="M576 242.6h-3l-.9 1.6c3.4-.6 3.4 2.9 4 0v-1.6h-.1z"
      />
      <path
        fill="#8ed1fe"
        d="M579.5 210.3l-5.9 3.9-.7.5c-.8.6-1.3 1.5-1.3 2.4l.9 26h3.8l1.5-26-1.9 2.9 7-1.4-3.4-8.3z"
      />
      <path
        fill="#fff"
        d="M569.6 247l.1.7h6.5l-.1-.7h-6.5zm-23.4 0l-.1.7h9.5l-.1-.7h-9.3z"
      />
      <path
        fill="#29427b"
        d="M544 202.1l13.4-6.5c2.5-1 4.8 3 2.3 4.1l-14.8 4.8c-.6.2-1.3-.1-1.6-.7-.2-.5 0-1.3.7-1.7z"
      />
      <path
        fill="#29427b"
        d="M556.7 195.7l11.6-17.3c.7-1.1 2.2-1.4 3.3-.7 1.1.7 1.4 2.2.7 3.3l-12.4 18.4c-1.2 1.7-5.4 0-3.2-3.7z"
      />
      <path
        fill="#f27083"
        d="M551.7 202.7h-27.9c-4.1 0-7.6-3.3-7.6-7.6v-27.9c0-4.1 3.3-7.6 7.6-7.6h27.9c4.1 0 7.6 3.3 7.6 7.6v27.9c-.2 4.2-3.5 7.6-7.6 7.6z"
      />
      <path
        fill="#f38ca0"
        d="M543.6 204.8l-2.5-.3c-.3 0-.6-.2-.8-.5l-1.3-1.7c-.6-.8-.2-2.1.6-2.2l3.7-.7c1.6-.2 3 1.3 3 3-.3 1.5-1.5 2.5-2.7 2.4z"
      />
      <path
        fill="#29427b"
        d="M568.3 205.9l-.2-25.8c0-2.1 1.7-3.7 3.8-3.7h14.6c2.1 0 3.7 1.6 3.5 3.7l-4.3 25.5.6 4.8-18.2-1.6.2-2.9z"
      />
      <path
        fill="#fff"
        d="M575.1 176.5h7.6c-3.2 7.1-3.3 18.8-2.6 33.6l-8.8-.8c1.8-16.4-2.8-29 3.8-32.8z"
      />
      <path
        fill="#f38ca0"
        d="M582 176.5c0 1.9-1.6 3.4-3.5 3.4s-3.4-1.5-3.3-3.4h6.8z"
      />
      <path
        fill="#29427b"
        d="M585.5 178.4l-9.8 20 2.4-1.4-15.4-.2c-.7 0-1.3.6-1.4 1.3 0 .7.5 1.1 1.1 1.3l15.3 2.5c.9.1 1.8-.3 2.3-1.1l.1-.1 9.8-20c.6-1.1.1-2.6-1.1-3.2-1.3-.7-2.7-.2-3.3.9z"
      />
      <path
        fill="#f38ca0"
        d="M561.2 201.3l-2.5-.3c-.3 0-.6-.2-.8-.6l-1.3-1.7c-.6-.8-.2-2.1.7-2.2l3.7-.6c1.6-.2 3 1.3 2.9 3.1-.2 1.5-1.4 2.6-2.7 2.3zm20-24.8h-4.3l.1-4.2 4-.6.2 4.8z"
      />
      <path
        fill="#29427b"
        d="M573.6 160.8c-.2.7 0 7.2 0 7.2h-1.4c-.8-2.6-2.1-7.3-1-9.3.8-1.5 4.1-1.7 4.1-1.7l-1.7 3.8z"
      />
      <path
        fill="#f38ca0"
        d="M571.8 161.8c0-2.4 1.7-3.9 5.3-3.9 3.5 0 5.3 1.4 5.3 3.9 0 1.9.3 5.5.2 7.3-.1 2.1-.8 3.4-2.4 4.8-1 .8-2.1 1.3-3.1 1.3s-2.2-.3-3.1-1.3c-1.6-1.4-2.3-2.6-2.4-4.8-.2-1.8.2-5.3.2-7.3z"
      />
      <path
        fill="#29427b"
        d="M581.4 160.7c.1 0 .1 0 0 0 .2.7 0 7.2 0 7.2h1.4c.7-2.2 1-5.4.8-8.2l-2.2 1z"
      />
      <path fill="#fff" d="M578 170.4c0 2.3-3.4 2.3-3.4 0 1.1.2 2.2.2 3.4 0z" />
      <path
        fill="#f38ca0"
        d="M584.4 167.4c-.2 1-.9 1.9-1.4 1.9-.6 0-.7-.9-.5-1.9.2-1 .9-1.9 1.4-1.9.5 0 .8.8.5 1.9z"
      />
      <path
        fill="#49341f"
        d="M578.4 166.4c0 .6.2.9.5.9.2 0 .5-.5.5-.9 0-.6-.2-.9-.5-.9-.2-.1-.5.3-.5.9zm-5.1 0c0 .6.2.9.5.9.2 0 .5-.5.5-.9 0-.6-.2-.9-.5-.9-.3-.1-.5.3-.5.9z"
      />
      <path
        fill="#d96c81"
        d="M575.9 165.8l-.8 3.2c0 .1.1.3.2.3h.1l1.4.1c.2 0 .5-.1.6-.5 0-.2-.1-.5-.5-.6h-.1l-1.4.1.3.3.2-2.9z"
      />
      <path
        fill="#29427b"
        d="M581.5 160.8h-9.8c-.7-6.2 11.7-8.8 11.9-1l-2.1 1z"
      />
      <path
        fill="#d96c81"
        d="M577.6 165.3c.1.1.3.1.5 0 .6-.5 1.4-.5 1.9 0 .1.1.3.1.5 0 .1-.1.1-.3-.1-.5-.8-.6-1.8-.6-2.6 0-.3.1-.3.4-.2.5zm-5.4 0c.1.1.3.1.5 0 .6-.5 1.4-.5 1.9 0 .1.1.3.1.5 0 .1-.1.1-.3-.1-.5-.8-.6-1.8-.6-2.6 0-.2.1-.4.4-.2.5z"
      />
      <path
        fill="#fff"
        d="M547.1 191.7H541c-1.6 0-3.2-.3-4.7-.9-.3-.1-.7-.2-1.1-.5-.7-.2-1.6-.6-2.2-.8-.1.7-.6 1.3-1.4 1.3h-4.5c-.7 0-1.4-.6-1.4-1.4v-11.9c0-.7.6-1.4 1.4-1.4h5c2.2-2.4 4.1-5.5 6-9.2.1-.3.5-.6.8-.7.3-.1.7 0 1 .1 1.4.8 2.2 1.9 2.5 3.3.5 1.7 0 3.8-1.4 6.4h7.4c.1 0 .2 0 .3.1.6.1 1.1.5 1.6.9.5.6.7 1.4.6 2.1-.1.6-.3 1.1-.7 1.6.2.5.3 1 .2 1.6-.1.6-.3 1.1-.7 1.6.2.5.3 1 .2 1.6-.1.6-.3 1.1-.7 1.6.2.5.3 1 .2 1.6-.2 1.4-1.3 2.4-2.5 2.6.5.4.3.4.2.4zm-15-3.9l.7.6c.6.5 1.7.8 2.6 1.1.5.1.9.3 1.3.5 1.4.6 2.9.8 4.3.8h6.2c1-.2 1.8-1 1.9-1.9.1-.5 0-.9-.3-1.3l-.2-.3.2-.2c.3-.3.6-.8.7-1.3.1-.5 0-.9-.3-1.3l-.2-.3.2-.2c.3-.3.6-.8.7-1.3.1-.5 0-.9-.3-1.3l-.2-.3.2-.2c.3-.3.6-.8.7-1.3.1-.6 0-1-.3-1.4-.2-.3-.7-.6-1.1-.7h-9.1l.3-.7c1.5-2.6 2.1-4.8 1.6-6.4-.2-1.1-1-2.1-2.2-2.7-.1-.1-.2-.1-.3 0-.1 0-.2.1-.2.2-1.9 3.9-4 7-6.2 9.5l-.1.1h-5.4c-.2 0-.5.2-.5.5v11.9c0 .2.2.5.5.5h4.5c.2 0 .5-.2.5-.5v-2.1h-.2z"
      />
      <path
        fill="#fff"
        d="M533 176.7h-.9v12h.9v-12zm68.9 373.7c-.2 0-.5-.2-.5-.5v-98.8c0-.2.2-.5.5-.5s.5.2.5.5V550c0 .3-.3.4-.5.4zm41.3 0c-.2 0-.3-.1-.5-.3l-19.2-99.3c0-.2.1-.5.3-.6.2 0 .5.1.6.3l19.3 99.3c0 .4-.2.6-.5.6.1 0 0 0 0 0z"
      />
      <path
        fill="#fff"
        d="M621.3 550.4c-.2 0-.3-.1-.5-.3l-19.3-99.3c0-.2.1-.5.3-.6.2 0 .5.1.6.3l19.3 99.3c.2.4 0 .6-.4.6.2 0 .2 0 0 0z"
      />
      <path
        fill="#fff"
        d="M624 451.1h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5H624c.2 0 .5.2.5.5-.2.3-.3.5-.5.5zm2.7 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.2.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.3.5-.5.5zm2.7 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.3.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5H635c.2 0 .5.2.5.5s-.3.5-.5.5zm2.7 14.1h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5 0 .4-.3.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5-.1.4-.3.5-.5.5z"
      />
      <path
        stroke="#6B84FB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M601.9 550.4c-.2 0-.5-.2-.5-.5v-98.8c0-.2.2-.5.5-.5s.5.2.5.5V550c0 .3-.3.4-.5.4zm41.3 0c-.2 0-.3-.1-.5-.3l-19.2-99.3c0-.2.1-.5.3-.6.2 0 .5.1.6.3l19.3 99.3c0 .4-.2.6-.5.6zm0 0c.1 0 0 0 0 0v0z"
      />
      <path
        stroke="#6B84FB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M621.3 550.4c-.2 0-.3-.1-.5-.3l-19.3-99.3c0-.2.1-.5.3-.6.2 0 .5.1.6.3l19.3 99.3c.2.4 0 .6-.4.6zm0 0c.2 0 .2 0 0 0v0z"
      />
      <path
        stroke="#6B84FB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M624 451.1h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5H624c.2 0 .5.2.5.5-.2.3-.3.5-.5.5zm2.7 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.2.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.3.5-.5.5zm2.7 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5s-.3.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5H635c.2 0 .5.2.5.5s-.3.5-.5.5zm2.7 14.1h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5 0 .4-.3.5-.5.5zm2.8 14.2h-21.9c-.2 0-.5-.2-.5-.5s.2-.5.5-.5h21.9c.2 0 .5.2.5.5-.1.4-.3.5-.5.5z"
      />
      <path
        fill="#0abce2"
        d="M611.9 375.9l-13.3-6.9c-.6-.3-1.4-.1-1.7.5-.5.6-.2 1.5.3 1.8l15.3 10.4c2.4 1.5 3.4-3.6-.6-5.8z"
      />
      <path
        fill="#a1a7ff"
        d="M632.6 366.8c-.8-1.1-2.3-1.5-3.4-.7l-14 9.3c-4.5 2.7-4.2 5.3-2.6 6.3.7.3 1.6.3 2.4-.1l.1-.1 16.8-11.1c1.1-.9 1.3-2.4.7-3.6z"
      />
      <path
        fill="#f38ca0"
        d="M600.3 367.8l-2.4-1.7c-.3-.2-.7-.3-1-.2l-2.2.3c-1 .2-1.5 1.4-.8 2.2l2.6 3.3c1.1 1.4 3.3 1.4 4.3-.1 1.1-1.2.8-2.9-.5-3.8z"
      />
      <path
        fill="#29427b"
        d="M611.3 446.1c-.3.5-.7.9-1 1-1.7.8-4.1.8-4.3 2.2l-.2.9H616l-.2-3.4-4.5-.7z"
      />
      <path
        fill="#f38ca0"
        d="M615.5 444.6h-3.3s-.5.7-.9 1.4c1.8 2.1 3.3 2.4 4.3.8l-.1-2.2zm11.2 14.3h-3.3l-.9 1.7c-.7 1.4 4.2 1.8 4.2 0v-1.7z"
      />
      <path
        fill="#29427b"
        d="M622.5 460.6l-.5.6c-1.3.6-2.3 1.4-2.3 2.3v.9h7l-.1-3.9c.1-.6-3.1.9-4.1.1z"
      />
      <path
        fill="#b4b9ff"
        d="M605.8 449.5l-.1.8h10.1v-.8h-10zm14.1 14.2v.7h7v-.7h-7z"
      />
      <path
        fill="#c04c85"
        d="M602.8 421.1l10.9-18.9c1-1.8 3.4-2.4 5.1-1.3l1.8 1.1c1.7 1.1 2.2 3.5.9 5.3l-8 12.4s-2.7 3.3-4.9 5.8c-1.3 1.6-3.7 1.7-5.1.5-1.4-1.2-1.6-3.4-.7-4.9z"
      />
      <path
        fill="#c04c85"
        d="M605 419.5c1.7-.3 3.3.6 3.9 2.2l7.1 23.7h-4.7l-8.6-21.3c-.9-1.9.2-4.1 2.3-4.6zm24.8-16.4l-.5 14.1c-.2 4.7-.3 9.4-.7 14.1l-1.5 28.1H623l-.9-28.1-.1-3.5-.2-3.6-.6-7-1.1-14 9.7-.1z"
      />
      <path
        fill="#c04c85"
        d="M627.3 407.2h-13c-1.4 0-2.5-1.3-2.6-2.7v-4.8h18.2v4.8c0 1.4-1.1 2.7-2.6 2.7z"
      />
      <path
        fill="#0abce2"
        d="M603 382.9l-10.8-10.3c-.5-.5-1.3-.5-1.7-.1-.6.5-.7 1.3-.2 1.8l11.7 14.3c2 2.2 4.2-2.5 1-5.7z"
      />
      <path
        fill="#0abce2"
        d="M613.6 366.6c-1.3-.6-2.7-.1-3.3 1.1l-7.2 15.1c-2.4 4.7-.9 6.6 1 6.6.8-.1 1.6-.6 1.9-1.4l.1-.1 8.7-18.2c.5-1 0-2.5-1.2-3.1z"
      />
      <path
        fill="#a8aeff"
        d="M589 338.7h-44.4c-4.2 0-7.7 3.4-7.7 7.7v20.7c0 4.2 3.4 7.7 7.7 7.7h6v8.6l8.6-8.6h29.7c4.2 0 7.7-3.4 7.7-7.7v-20.7c0-4.3-3.4-7.7-7.6-7.7z"
      />
      <path
        fill="#fff"
        d="M583.5 350.1H550c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h33.5c.8 0 1.4.6 1.4 1.4 0 .7-.6 1.4-1.4 1.4zm0 7.2H550c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h33.5c.8 0 1.4.6 1.4 1.4 0 .9-.6 1.4-1.4 1.4zm0 7.4H550c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4h33.5c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4z"
      />
      <path
        fill="#f38ca0"
        d="M594.6 372.4l-.6-2.9c-.1-.3-.2-.7-.6-.9l-1.8-1.3c-.9-.6-2.1 0-2.2 1l-.3 4.2c-.1 1.8 1.5 3.3 3.2 3 1.6-.1 2.6-1.6 2.3-3.1z"
      />
      <path
        fill="#0abce2"
        d="M611.6 405.4l-1-37.3c0-2.2 1.7-3.9 3.9-3.9h15.7c2.2 0 3.9 1.7 3.9 3.9l-4 37.3h-18.5z"
      />
      <path
        fill="#f38ca0"
        d="M620.8 361.8c-3.8 0-6.8-3-6.8-6.8v-4.4c0-3.8 3-6.8 6.8-6.8s6.8 3 6.8 6.8v4.4c-.1 3.7-3.2 6.8-6.8 6.8z"
      />
      <path fill="#f38ca0" d="M618.8 364.2h5.9v-5.5h-5.9v5.5z" />
      <path
        fill="#6a3a14"
        d="M620.8 343.9c3.8 0 6.8 3 6.8 6.8v4.4c0 2.3-1.1 4.2-2.7 5.5-1.4 1-3.3.7-4.3-.7l-2.5-3.4-2.4-10c1.1-1.7 2.9-2.6 5.1-2.6z"
      />
      <path
        fill="#6a3a14"
        d="M614.7 347.5c1.1-2.2 3.4-3.7 5.9-3.7 3.8 0 6.8 3 6.8 6.8v3.3h-10.2c-1.7.1-2.9-5.4-2.5-6.4z"
      />
      <path
        fill="#6a3a14"
        d="M622.8 343.9c2.1-1.7 7.1.2 7.8 2.3-.7.1-1.4.1-2.1 0 1.5.3 2.3 2.4 1 4.1-.3-.7-1-1.7-1.7-1.9.9.9 1.4 2.9.5 4.1-.6-.7-.9-1.1-1.3-2.1.1.8.1 1.7-.6 2.2-1.3-2.6-3.3-6.4-3.6-8.7z"
      />
      <path
        fill="#6a3a14"
        d="M623.1 345.3c.1-4.5-6.5-4.9-11.6-1.3 1.4-.3 3.2-.8 4-.7-2.2.3-3.8 1.5-4.7 3.2.6.3 1.6.3 2.6 0-1 .3-1.7 1.4-2.1 2.4 1.8.2 2.7-.5 3.3-1.3-.6.7-1.4 2.1-1.5 3.5 1.3-1.4 4-2.3 5.6-2.4-1 .5-2.5 1.1-3.3 2.1 2.5.2 5 0 6.5-1.6.2.7 1 1.7 1.8 2.1 1-1.3 1.8-4.6-.6-6z"
      />
      <path
        fill="#f38ca0"
        d="M617.3 353.7v2.1c0 .3.2.6.6.6.3 0 .6-.2.6-.6v-2.1c0-.3-.2-.6-.6-.6-.3 0-.6.2-.6.6z"
      />
      <path
        fill="#c04c85"
        d="M442.4 369.1c0-2.1-1.8-3.8-3.9-3.6-2.1.1-3.7 2.1-3.4 4.1l3.5 28.1h4.3l-.5-28.6z"
      />
      <path
        fill="#f38ca0"
        d="M438.6 397.6l.1.9c.1 1.1 1.1 1.9 2.3 1.8 1.1-.1 1.9-1 1.9-2.2v-.7h-4.3v.2z"
      />
      <path
        fill="#c04c85"
        d="M438.1 342.1l-2.5.2c-2.1.1-3.7 2.1-3.3 4.1l3 23.5c.2 1.9 1.9 3.3 3.9 3.2 1.9-.1 3.4-1.7 3.4-3.7l-.5-23.6c-.1-2.2-2-3.8-4-3.7z"
      />
      <path
        fill="#c04c85"
        d="M427.5 349.3H438c2.2 0 3.9-1.7 3.9-3.9v-3.9h-18.4v3.9c0 2.1 1.9 3.9 4 3.9z"
      />
      <path
        fill="#c04c85"
        d="M429.7 342h-2.5c-2.1 0-4 1.7-4.3 3.9l-2.7 23.6c-.2 1.9 1.1 3.4 3.1 3.4 1.9 0 3.8-1.5 4.2-3.4l5.3-23.6c.3-2.1-1-3.9-3.1-3.9z"
      />
      <path
        fill="#f38ca0"
        d="M416.8 398l-.1.5c-.1 1.1.7 2.1 1.7 2.1 1.1 0 2.2-.9 2.4-2.1l.1-.5h-4.1z"
      />
      <path
        fill="#c04c85"
        d="M427.5 369.2c.5-2.1-.9-3.9-3-3.9s-4.1 1.7-4.3 3.9l-3.3 28.7h4.2l6.4-28.7z"
      />
      <path
        fill="#f38ca0"
        d="M439.6 323.1l5.7-.1.5 8.6c.1 1.5-.9 2.7-2.3 2.9-1.4.1-2.5-1-2.7-2.5l-1.2-8.9z"
      />
      <path
        fill="#fff"
        d="M444.6 311.2c-.1-2.1-1.8-3.8-3.7-3.7-1.8.1-3.2 1.9-3 4l1.6 11.8 5.7-.1-.6-12z"
      />
      <path
        fill="#f38ca0"
        d="M445.5 334.3l17.6-13.6c.7-.6.8-1.5.3-2.2-.5-.7-1.5-.9-2.2-.5l-18.8 12c-1.3.8-1.5 2.4-.7 3.7 1.1 1.3 2.7 1.5 3.8.6z"
      />
      <path
        fill="#f38ca0"
        d="M464.8 321.9l2.5-1.4c.3-.2.6-.5.7-.8l.6-2.2c.3-1-.6-1.9-1.6-1.7l-4.1 1c-1.8.5-2.6 2.4-1.8 4 .7 1.4 2.4 1.8 3.7 1.1z"
      />
      <path
        fill="#6b84fb"
        d="M443.6 310.6c.1-1.8-1.5-4-3.5-4.7-6.1-1.7-12.5-1.7-18.4 0-2.1.6-3.3 2.9-3.1 4.7 1.6 10 3.3 20.4 5 31.4H442c.5-10.9 1-21.4 1.6-31.4z"
      />
      <path
        fill="#29427b"
        d="M418.5 310.6c.8 4.6 1.9 12.3 2.9 19 1.1 7.8.1 18.1.1 18.1h8.1l2.3-17.8-6.5-25c-1.3.2-2.5.5-3.8.9-2.1.8-3.4 3-3.1 4.8zm20.9-4.8l-3.4 24.1 2.1 17.9h4c.6-14.5 1-27.1 1.5-37.1.1-1.8-1.5-4-3.5-4.7-.3-.1-.4-.2-.7-.2z"
      />
      <path
        fill="#fff"
        d="M446.8 347.5h34.7c3.3 0 5.9-2.6 5.9-6v-34.7c0-3.3-2.6-6-5.9-6h-34.7c-3.3 0-6 2.6-6 6v34.7c0 3.3 2.6 6 6 6z"
      />
      <path
        fill="#6c74d1"
        d="M459.8 335.8c.2-1.8.1-3.8-.1-5.8-.7-.7-1.4-1.4-1.9-2.3-.8-1.3-2.5-6.2-2.5-9 0-1.5.2-2.9.7-4-1-.7-1.8-1.8-1.9-3.2 4.9 1.3 3.4-4.5 10.9-4.5 2.5 0 5.3 1.1 7 3.2.3.5.8 1.7.5 2.3-.1.3-.2.6-.6 1.1.8 1.5 1.3 3.2 1.3 5.2 0 2.9-1.7 7.8-2.5 9-.6.9-1.3 1.7-1.9 2.3-.2 2.1-.3 4-.1 5.8 1.1.3 3.7.7 5.8 1.1-2.3 2.9-5.9 4.7-10.2 4.7-4.1 0-7.8-1.8-10.1-4.7 1.9-.5 4.6-1 5.6-1.2z"
      />
      <path
        fill="#f38ca0"
        d="M436.2 305.1c-3.3-.6-6.6-.6-9.8-.2l7.2 9c0 .1 3-8.8 2.6-8.8zm-19.8 16.6l5.6 1.1-1.5 8.6c-.2 1.5-1.5 2.5-2.9 2.3-1.4-.2-2.3-1.6-2.2-3.1l1-8.9z"
      />
      <path
        fill="#6b84fb"
        d="M424 311.2c.3-2.1-.9-4-2.7-4.4-1.8-.3-3.5 1.1-3.8 3.3l-1.1 11.8 5.6 1.1 2-11.8z"
      />
      <path
        fill="#c04c85"
        d="M416.7 398.4h4.3c.5 0 .9-.3.9-.8l.2-1.3c.1-.5-.2-.8-.7-.8h-4.3c-.5 0-.9.3-.9.8l-.2 1.3c-.1.4.2.8.7.8zm22.1.2l4.3-.3c.5 0 .8-.5.8-.9l-.1-1.3c0-.5-.5-.8-.9-.8l-4.3.3c-.5 0-.8.5-.8.9l.1 1.3c-.1.4.4.8.9.8z"
      />
      <path fill="#f38ca0" d="M429.5 305.4h3.7v-4.6l-4.5-.6.8 5.2z" />
      <path
        fill="#29427b"
        d="M435.7 289c.2.7 0 7.3 0 7.3h1.4c.8-2.6 1.1-6.9.6-10.1l-2 2.8z"
      />
      <path
        fill="#f38ca0"
        d="M437.3 290.1c0-2.5-1.7-3.9-5.4-3.9s-5.4 1.5-5.4 3.9c0 1.9-.3 5.5-.2 7.4.1 2.2.8 3.5 2.4 4.9 1 .8 2.2 1.3 3.2 1.3s2.2-.5 3.2-1.3c1.6-1.4 2.3-2.7 2.4-4.9.1-1.9-.2-5.5-.2-7.4z"
      />
      <path
        fill="#29427b"
        d="M427.7 298.6l5.6-1 4.2 1c-.2 1.6-1 2.7-2.3 3.9-1 .8-2.2 1.3-3.2 1.3s-2.2-.5-3.2-1.3c-1.6-1.4-2.3-2.7-2.4-4.9v-1.3h1.4v2.3h-.1zm1.7-15.3h5.4c1.7 0 4.6-.2 4.6 1.7 0 1.7-1.6 3.8-5 3.8-3.9 0-6.3-1.1-6.6.1-.2.7 0 7.3 0 7.3h-1.4c-.8-2.6-1.1-6.9-.6-10.1.3-1.6 1.7-2.8 3.6-2.8z"
      />
      <path
        fill="#fff"
        d="M431.2 298.8c0 2.3 3.5 2.3 3.5 0-1.1.2-2.4.2-3.5 0z"
      />
      <path
        fill="#f38ca0"
        d="M424.7 295.7c.2 1.1.9 1.9 1.5 1.9s.7-.9.5-1.9c-.2-1.1-.9-1.9-1.5-1.9s-.7.8-.5 1.9z"
      />
      <path
        fill="#49341f"
        d="M431.1 293.3h-1.9c-.1 0-.3-.1-.3-.3 0-.1.1-.3.3-.3h1.9c.1 0 .3.1.3.3 0 .1-.1.3-.3.3zm5.6 0h-1.9c-.1 0-.3-.1-.3-.3 0-.1.1-.3.3-.3h1.9c.1 0 .3.1.3.3-.1.1-.2.3-.3.3zm-6 1.4c0 .6-.2.9-.5.9s-.5-.5-.5-.9c0-.6.2-.9.5-.9s.5.3.5.9zm5.3 0c0 .6-.2.9-.5.9-.2 0-.5-.5-.5-.9 0-.6.2-.9.5-.9s.5.3.5.9z"
      />
      <path
        fill="#d96c81"
        d="M433.4 294.2l.1 3-1-.1c-.7 0-.7 1 0 1l1.8-.2-.1-.3-.8-3.4z"
      />
      <path
        fill="#29427b"
        d="M437.4 281.9c.3.7 0 1.6-.8 1.6-2.6.3-7.6.8-7.6.8-.1 0 4.9-2.1 7-3 .6-.1 1.2 0 1.4.6z"
      />
      <path
        fill="#f38ca0"
        d="M418 334l22 3.9c.8.1 1.6-.3 1.8-1.3.2-.8-.3-1.7-1.1-1.8l-21.5-5.7c-1.4-.3-2.9.5-3.2 1.9-.4 1.2.6 2.7 2 3z"
      />
      <path
        fill="#f38ca0"
        d="M440.1 339.9l2.7.9c.3.1.7.1 1-.1l1.9-1c.9-.5 1-1.7.1-2.4l-3.5-2.4c-1.5-1-3.5-.3-4.1 1.4-.2 1.6.4 3.1 1.9 3.6z"
      />
      <path
        fill="#29427b"
        d="M443.4 401.1c.3.5.7.9 1 1 1.7.8 4.1.8 4.5 2.3l.2 1H439l.2-3.4 4.2-.9z"
      />
      <path
        fill="#f38ca0"
        d="M439.1 399.7c0-1.4 2.7-1.4 3.3 0 0 0 .5.7.9 1.4-1.9 2.1-3.3 2.4-4.3.8l.1-2.2z"
      />
      <path
        fill="#29427b"
        d="M420.6 401.5c.3 0 .5.2.6.6 1.1.6 2.1 1.4 1.9 2.4l-.2 1h-7l.8-3.9h3.9v-.1z"
      />
      <path
        fill="#f38ca0"
        d="M416.9 399.8c.3-1.6 3-2.1 3.3 0l.7 1.8c-3.5-.7-4.2 3-4.3 0l.3-1.8z"
      />
      <path
        fill="#6b84fb"
        d="M423 404.6l-.1.8h-7.1l.2-.8h7zm25.9 0l.1.8h-10.2v-.8h10.1z"
      />
      <path
        fill="#6b84fb"
        d="M449.6 406.2c0-.5-.3-.8-.8-.8h-34.2c-.5 0-.8.3-.8.8V550c0 .5.3.8.8.8s.8-.3.8-.8v-33.3h32.7V550c0 .5.3.8.8.8s.8-.3.8-.8V406.2h-.1c-.1 0 0 0 0 0zm-34.3 92.7v-13.1H448v13.1h-32.7zm0-14.7v-14.7H448v14.7h-32.7zm0-16.2v-13.1H448V468h-32.7zm0-14.7v-14.7H448v14.7h-32.7zm0-16.1v-13.1H448v13.1h-32.7zm0-14.7V407H448v15.5h-32.7zm0 77.9H448V515h-32.7v-14.6z"
      />
      <path
        fill="#f38ca0"
        d="M671.3 489.6v14.2c0 2.4 0 4.7.1 7.1v5.3c0 .6 0 1.1.1 1.7l.6 7.1c.2 2.4.3 4.7.6 7.1l.7 7.1c.2 2.4.5 4.7.7 7.1h2.5c.1-2.4.2-4.7.3-7.1l.3-7.1c.1-2.4.2-4.7.3-7.1l.2-7c0-.6.1-1.1.1-1.7l.2-1.7.3-3.6c.2-2.3.5-4.7.7-7l.7-7c.2-2.3.5-4.7.6-7l-9-.4z"
      />
      <path
        fill="#f38ca0"
        d="M679.6 490.2c.2 2.3.3 4.5.6 6.8l.7 6.8c.2 2.3.5 4.5.7 6.8l.7 6.8v.2l1.4 7.2c.5 2.4.9 4.8 1.4 7.2l1.4 7.2c.5 2.4.9 4.8 1.5 7.2l2.5-.3c-.1-2.4-.2-4.9-.3-7.3l-.5-7.3c-.1-2.4-.3-4.8-.5-7.3l-.5-7.3v.2l.1-6.8c0-2.3.1-4.6.1-6.8v-13.6l-9.3.3z"
      />
      <path
        fill="#6c74d1"
        d="M679 511.1v-.5c.1-.7.1-1.4.2-2.1.1-1.5.3-3.1.5-4.6l.7-7c.1-.8.1-1.6.2-2.5.1-1.5.2-3 .3-4.6v-.5l-10.2-.5v22.1h8.2v.2h.1z"
      />
      <path
        fill="#6c74d1"
        d="M689.2 511.1v-21.9l-10.2.5v.5l.3 4.5c.1.8.1 1.6.2 2.3l.7 6.8c.1 1.5.3 3.1.5 4.6.1.7.1 1.5.2 2.2l.1.6h8.2v-.1z"
      />
      <path
        fill="#6c74d1"
        d="M686.7 494.5h-13c-1.4 0-2.5-1.3-2.5-2.7v-2.5c0-1.3 1-2.3 2.3-2.3h13.7c1.3 0 2.2 1 2.3 2.2v2.5c-.2 1.7-1.3 2.8-2.8 2.8z"
      />
      <path
        fill="#f38ca0"
        d="M673.7 453.6l-20-8.8.1 4.5 18.4-10.1c.7-.3.9-1.1.6-1.8-.3-.6-1-.8-1.7-.6l-19.4 8c-1.3.5-1.8 1.9-1.3 3.1.2.6.7 1 1.3 1.3h.1l20 8.8c1.3.6 2.6 0 3.2-1.3.5-1.1-.1-2.5-1.3-3.1z"
      />
      <path
        fill="#c04c85"
        d="M671.2 459c1.6.6 3.4 0 4.2-1.6.9-1.7.2-3.8-1.4-4.7-2.7-1.5-5.9-2.4-5.9-2.4l-.9 7.1 4 1.6z"
      />
      <path
        fill="#f38ca0"
        d="M673.5 440.5l2.6-1.3c.3-.1.6-.5.7-.8l.7-2.1c.3-.9-.5-1.9-1.5-1.8l-4.1.7c-1.7.3-2.7 2.2-2.1 3.8.9 1.6 2.5 2.2 3.7 1.5z"
      />
      <path
        fill="#c04c85"
        d="M671 485.3l-1-30.1c0-2.2 1.7-3.9 3.9-3.9h15.4c2.2 0 3.9 1.7 3.9 3.9l-3.9 29.8.8 5.3h-19.5l.4-5z"
      />
      <path
        fill="#d96c81"
        d="M684.7 451.2c0 1.9-1.6 3.7-3.7 3.7-1.9 0-3.7-1.6-3.7-3.7h7.4zm-.5 0h-4.7l.1-4.5 4.2-.6.4 5.1z"
      />
      <path
        fill="#18105d"
        d="M677 435.1c-.2.7 0 7.2 0 7.2h-1.4c-.8-2.6-2.1-7.3-1-9.3.8-1.5 4.1-1.7 4.1-1.7l-1.7 3.8z"
      />
      <path
        fill="#f38ca0"
        d="M675.5 436.2c0-2.4 1.7-3.9 5.3-3.9 3.6 0 5.3 1.4 5.3 3.9 0 1.9.3 5.5.2 7.3-.1 2.2-.8 3.4-2.4 4.8-1 .8-2.1 1.3-3.1 1.3s-2.2-.3-3.1-1.3c-1.6-1.4-2.3-2.6-2.4-4.8-.3-1.9.2-5.4.2-7.3z"
      />
      <path
        fill="#18105d"
        d="M684.8 442.3l-1 1.9-4.3-.5-4.2.6.2 2.6c.1 1.6 1.5 2.9 3.1 2.9h2.4c2.2 0 4.1-1.4 4.8-3.3.5-1.3.6-2.6.3-4.2h-1.3zm0-7.3c0 .1 0 .1 0 0 .2.7 0 7.3 0 7.3h1.4c.7-2.2 1-5.5.8-8.4l-2.2 1.1z"
      />
      <path
        fill="#18105d"
        d="M674.5 432.5c.2-1.7 1.9-3.9 7.6-3.9 5.5 0 6.2 5 9.4 5.5-3.8 2.6-12.8 2.2-15.9 1.3l-1.3-1.3c0-.6.2-1.2.2-1.6z"
      />
      <path
        fill="#fff"
        d="M681.4 444.7c0 2.3-3.4 2.3-3.4 0 1.1.2 2.3.2 3.4 0z"
      />
      <path
        fill="#d96c81"
        d="M687.8 441.7c-.2 1-.9 1.9-1.4 1.9-.5 0-.7-.9-.5-1.9.2-1 .9-1.9 1.4-1.9.5 0 .7.8.5 1.9z"
      />
      <path
        fill="#49341f"
        d="M681.9 440.7c0 .6.2.9.5.9s.5-.5.5-.9c0-.6-.2-.9-.5-.9-.4 0-.5.3-.5.9zm-5.3 0c0 .6.2.9.5.9s.5-.5.5-.9c0-.6-.2-.9-.5-.9s-.5.3-.5.9z"
      />
      <path
        fill="#d96c81"
        d="M679.2 440.2l-.8 3.2c0 .1.1.3.2.3h.1l1.4.1c.2 0 .5-.1.6-.5 0-.2-.1-.5-.5-.6h-.1l-1.4.1.3.3.2-2.9z"
      />
      <path
        fill="#f38ca0"
        d="M688.6 455.5c.1 1.8.3 3.8.5 5.6.2 1.8.3 3.8.6 5.6.2 1.8.5 3.7.7 5.6l.7 5.6v-.3l-.1 5.2v10.4c0 1.7.1 3.4.1 5.2 0 .7.7 1.3 1.4 1.3s1.1-.6 1.3-1.1c.2-1.7.5-3.4.7-5 .2-1.7.3-3.4.6-5 .2-1.7.3-3.4.5-5l.5-5v-.3l-.5-5.6c-.2-1.8-.3-3.8-.6-5.6-.2-1.8-.5-3.7-.7-5.6-.2-1.8-.5-3.7-.8-5.6-.2-1.4-1.5-2.3-2.7-2.1-1.4-.7-2.3.4-2.2 1.7z"
      />
      <path
        fill="#c04c85"
        d="M694.5 454.8c-.3-1.7-1.8-2.9-3.5-2.7-1.9.1-3.3 1.7-3.2 3.7.2 3.1.6 6.3.9 9.4l7.4-3.1-1.6-7.3z"
      />
      <path
        fill="#f38ca0"
        d="M695.7 499.2l-.1 2.9c0 .3-.2.7-.5.9l-1.6 1.5c-.8.7-1.9.3-2.2-.7l-.9-4.1c-.5-1.7.9-3.4 2.7-3.3 1.6 0 2.7 1.3 2.6 2.8z"
      />
      <path
        fill="#737ade"
        d="M670.7 511.1h8.6l.5-4.2h-9v4.2h-.1zm10.1 0h8.7l.2-4.2h-9.4l.5 4.2z"
      />
      <path
        fill="#c04c85"
        d="M671.8 547.3c.3-.2.8-.9 1.1-1.6h4.1l.2 4.7h-10.1v-.9c0-1.8 3-1.4 4.7-2.2z"
      />
      <path fill="#19325c" d="M674.5 549.2h2.6l.1 1.3h-10.1v-.5h5.4l2-.8z" />
      <path
        fill="#fff"
        d="M672.1 546.9h.6c.2 0 .7-.2.8-.5l.8-1.4c.1-.2.1-.5-.2-.5h-.6c-.2 0-.7.2-.8.5l-.8 1.4c-.1.2 0 .5.2.5z"
      />
      <path
        fill="#c04c85"
        d="M686.6 547.2c.3-.2.6-.9.7-1.5h3.8l.5 4.7h-6.9l-.1-.9c-.3-1.4.7-1.5 2-2.3z"
      />
      <path fill="#19325c" d="M689.5 549.3h1.8l.1 1.1h-7v-.4h3.7l1.4-.7z" />
      <path
        fill="#fff"
        d="M687.1 546.9h.9c.2 0 .6-.2.6-.5l.3-1.4c.1-.2-.1-.5-.5-.5h-.9c-.2 0-.6.2-.6.5l-.3 1.4c.1.2.2.5.5.5z"
      />
      <path
        fill="#49341f"
        d="M680.8 439.6c.1.1.3.1.5 0 .6-.5 1.4-.5 1.9 0 .1.1.3.1.5 0 .1-.1.1-.3-.1-.5-.8-.6-1.8-.6-2.6 0-.2.1-.3.3-.2.5zm-5.3 0c.1.1.3.1.5 0 .6-.5 1.4-.5 1.9 0 .1.1.3.1.5 0 .1-.1.1-.3-.1-.5-.8-.6-1.8-.6-2.6 0-.3.1-.3.3-.2.5z"
      />
      <path
        fill="#d9e2eb"
        d="M131.8 545.4c0 2.7-2.3 5-5 5s-5-2.3-5-5 2.3-5 5-5c2.7-.1 5 2.2 5 5z"
      />
      <path
        fill="#a8aeff"
        d="M129.7 545.4c0 1.6-1.4 3-3 3s-3-1.4-3-3 1.4-3 3-3 3 1.4 3 3z"
      />
      <path
        fill="#ddd9ff"
        d="M126.7 546c-.6 0-1-.3-1.3-.8l-2.6-6.4h5.3v5.8c-.1.8-.6 1.4-1.4 1.4z"
      />
      <path fill="#a8aeff" d="M128 538.7h-5.1l.6 1.6h4.6v-1.6h-.1z" />
      <path fill="#ddd9ff" d="M131.8 490.6H87.9v48.1h43.9v-48.1z" />
      <path
        fill="#d9e2eb"
        d="M98.6 499.9h22.5c.6 0 1-.5 1-1 0-.6-.5-1-1-1H98.6c-.6 0-1 .5-1 1-.1.4.4 1 1 1zm22.4 2.3H98.6c-.6 0-1 .5-1 1 0 .6.5 1 1 1h22.5c.6 0 1-.5 1-1s-.5-1-1.1-1zm0 4.5H98.6c-.6 0-1 .5-1 1s.5 1 1 1h22.5c.6 0 1-.5 1-1s-.5-1-1.1-1z"
      />
      <path
        fill="#d9e2eb"
        d="M90.4 536.2v-43.1l-2.5-2.5v48.1h43.9v-2.5H90.4z"
      />
      <path fill="#ddd9ff" d="M87.9 490.6h-44v48.1h43.9v-48.1h.1z" />
      <path
        fill="#d9e2eb"
        d="M54.7 499.9h22.5c.6 0 1-.5 1-1 0-.6-.5-1-1-1H54.7c-.6 0-1 .5-1 1-.2.4.4 1 1 1zm22.4 2.3H54.7c-.6 0-1 .5-1 1 0 .6.5 1 1 1h22.5c.6 0 1-.5 1-1-.1-.5-.5-1-1.1-1zm0 4.5H54.7c-.6 0-1 .5-1 1s.5 1 1 1h22.5c.6 0 1-.5 1-1-.1-.5-.5-1-1.1-1z"
      />
      <path
        fill="#d9e2eb"
        d="M46.4 536.2v-43.1l-2.5-2.5v48.1h43.9v-2.5H46.4zM0 545.4c0 2.7 2.3 5 5 5s5-2.3 5-5-2.3-5-5-5c-2.7-.1-5 2.2-5 5z"
      />
      <path
        fill="#a8aeff"
        d="M2.1 545.4c0 1.6 1.4 3 3 3s3-1.4 3-3-1.4-3-3-3c-1.8 0-3 1.4-3 3z"
      />
      <path
        fill="#ddd9ff"
        d="M5 546c.6 0 1-.3 1.3-.8l2.6-6.4H3.7v5.8c0 .8.6 1.4 1.3 1.4z"
      />
      <path fill="#a8aeff" d="M3.7 538.7H9l-.6 1.6H3.7v-1.6z" />
      <path fill="#ddd9ff" d="M0 538.7h43.9v-48.1H0v48.1z" />
      <path fill="#d9e2eb" d="M2.5 536.2v-43.1L0 490.6v48.1h43.9v-2.5H2.5z" />
      <path
        fill="#d9e2eb"
        d="M10.8 499.9h22.4c.6 0 1-.5 1-1 0-.6-.5-1-1-1H10.8c-.6 0-1 .5-1 1-.2.4.4 1 1 1zm22.4 2.3H10.8c-.6 0-1 .5-1 1 0 .6.5 1 1 1h22.4c.6 0 1-.5 1-1s-.4-1-1-1zm0 4.5H10.8c-.6 0-1 .5-1 1s.5 1 1 1h22.4c.6 0 1-.5 1-1s-.4-1-1-1z"
      />
      <path fill="#bcc2c6" d="M41.3 483.5H26v7.1h15.3v-7.1z" />
      <path
        fill="#fff"
        d="M42.7 488.1h-18c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h18c.7 0 1.3-.6 1.3-1.3-.1-.7-.6-1.3-1.3-1.3z"
      />
      <path fill="#a8aeff" d="M61.9 445.9H5.5v37.7h56.4v-37.7z" />
      <path fill="#1ed4df" d="M59.4 481.1v-32.8H7.9v32.8h51.5z" />
      <path
        fill="#fff"
        d="M11.7 459.8h7.2v-7.2h-7.2v7.2zm21.1-2.5h-11v2.6h11v-2.6zm-10.9-4.7v2.6h16v-2.6h-16zM11.7 472h7.2v-7.2h-7.2v7.2zm21.1-2.5h-11v2.6h11v-2.6zm-10.9-4.7v2.6h16v-2.6h-16z"
      />
      <path fill="#bcc2c6" d="M119.2 483.5h-15.3v7.1h15.3v-7.1z" />
      <path
        fill="#fff"
        d="M120.5 488.1h-18c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3h18c.7 0 1.3-.6 1.3-1.3-.1-.7-.6-1.3-1.3-1.3z"
      />
      <path fill="#a8aeff" d="M139.7 445.9H83.3v37.7h56.4v-37.7z" />
      <path fill="#f27083" d="M137.2 481.1v-32.8H85.8v32.8h51.4z" />
      <path
        fill="#fff"
        d="M92.1 476.8h4v-4h-4v4zm5.9 0h4v-11h-4v11zm6 0h4v-17.9h-4v17.9zm5.9 0h4v-22.4h-4v22.4zm6 0h4v-20.2h-4v20.2zm5.9 0h4V452h-4v24.8zm6-13.2v13.3h4v-13.3h-4z"
      />
      <path
        fill="#686ddf"
        d="M48.2 489.3l-2.4.6c-2.1.5-3.2 2.5-2.7 4.5l6.3 22.4c.5 1.8 2.4 3 4.2 2.5 1.8-.5 3.1-2.2 2.7-4.1l-3.8-23c-.3-2.1-2.3-3.4-4.3-2.9z"
      />
      <path
        fill="#686ddf"
        d="M47.6 496.6l10.2-2.3c2.1-.5 3.4-2.5 3-4.7l-.8-3.8-17.6.6c-.2 3.3 0 4.5.7 7.1.3 2.3 2.3 3.6 4.5 3.1zm1.5 20l-1.5 27c0 1.1.8 1.9 1.8 1.9 1.1 0 2.2-.9 2.3-1.9l4.6-27c.3-2.1-1.1-3.8-3.2-3.8-2.1.1-3.9 1.8-4 3.8z"
      />
      <path
        fill="#f38ca0"
        d="M49.1 549.4c-1 0-1.8-.8-1.7-1.9l.2-3.9h4.1l-.6 3.9c0 1.1-1 1.9-2 1.9z"
      />
      <path
        fill="#18105d"
        d="M51.5 545.5c-1.5.6-2.9.7-4.1 0l-.5 4.9h6.6l.1-1.3c0-.5-.1-.8-.3-1.1l-.3-.5-1.5-2z"
      />
      <path
        fill="#686ddf"
        d="M59.3 515.3l3.5 28.6c.1 1.1 1.1 1.9 2.2 1.8 1.1-.1 1.9-1 1.9-2.2l-.6-28.9c0-2.1-1.8-3.7-3.9-3.5-1.9.3-3.5 2.1-3.1 4.2z"
      />
      <path
        fill="#f38ca0"
        d="M65.3 549.4c-1 .1-1.9-.7-2.1-1.7l-.3-3.9 4.1-.3.1 3.9c.1 1.1-.7 2-1.8 2z"
      />
      <path
        fill="#18105d"
        d="M73.9 550.4v-.7c0-.8-.5-1.4-1.3-1.6l-3.2-1-2.5-1.6c-.1 1.4-2.4 1.5-4 0v4.9h11z"
      />
      <path
        fill="#686ddf"
        d="M56.2 487.4l-2.4.7c-1.9.6-3.1 2.7-2.4 4.7l7.8 22c.7 1.8 2.6 2.7 4.5 2.3 1.8-.6 3-2.4 2.5-4.4L60.7 490c-.4-2-2.5-3.1-4.5-2.6z"
      />
      <path
        fill="#2f3542"
        d="M63 486.9L74.1 457c.7-2.2-.5-4.6-2.6-5.4l-11.6-3.9c-2.2-.7-9.4 2.2-11.2 9.4L45.3 477l-4 11.1 20.3 3.6 1.4-4.8z"
      />
      <path
        fill="#f38ca0"
        d="M87.2 485.6l3 .8c.3.1.7.3.9.6l1.3 1.8c.6.9 0 1.8-1.1 1.8l-4.5.1c-1.9 0-3.4-1.7-3-3.3.2-1.3 1.8-2.1 3.4-1.8z"
      />
      <path
        fill="#f38ca0"
        d="M87 488.1c.6.5.7 1.3.3 1.9-.5.7-1.4.9-2.1.5l-3.1-1.8 1.8-3 3.1 2.4z"
      />
      <path
        fill="#2f3542"
        d="M68.6 479.8l.1-23.4c0-1.5 1.3-2.7 2.7-2.6 1.5 0 2.7 1.3 2.6 2.7l.3 20.3-5.7 3zm6-3l9.4 8.8-1.7 3-13.6-8.8 5.9-3z"
      />
      <path
        fill="#686ddf"
        d="M47 549.5l-.1.9h6.6l.1-.9H47zm16 .9h10.9V549.5H63v.9z"
      />
      <path
        fill="#f38ca0"
        d="M74.7 441.4l.3 3.4c0 .2-.2.5-.5.5l-1.5-.5 1.7-3.4z"
      />
      <path
        fill="#f38ca0"
        d="M75.5 438.8c.7-2.1.3-5.3-4.5-6.9-3-1-6.4-.7-7.7 2.9-.6 1.6-.3 5.2-.2 6.8.2 2.7 1.4 6.1 5.9 7.7.6.2 1.5.5 2.1.1 1.3-.8 2.1-3.1 2.5-4.2.3-.8.7-2.1 1-3.3.1-.5.7-.9.8-1.4.2-.7 0-1.3.1-1.7z"
      />
      <path
        fill="#f38ca0"
        d="M66.4 449.9l-6.4-2.2 3.1-3.8c.5-.6 1-1.5 2.4-1 2.9.9 3.2 3.2 2.4 4.7l-1.5 2.3z"
      />
      <path
        fill="#6a3a14"
        d="M76.4 434.6c-.6-2.2-2.3-4.1-4.5-4.9-2.4-.8-6.5.3-6.5.3-8.5-2.9-3.2 13.1-3.2 13.1-1.5 2.4 3 2.9 5.5 1.7.5-.2 1.3-.6 1.4-1.5-.7-.2-.7-1.3-.2-2.4.3-1.1 1.3-2.2 1.6-1.7 1 1.1 1.1.1 1.1-.2.5-1.3 3.7-1.9 4.2-2.3.9-.3.9-1.1.6-2.1z"
      />
      <path
        fill="#854919"
        d="M75.4 435c-.3-1.4-1.5-2.6-3.1-3.1-4.8-1.5-3.1 12.5-8.7 12.5 1 .2 2.6.1 3.8-.3.2-.1.3-.2.5-.2-.3-.6-.5-1.5 0-3 .3-1.1 1.5-2.9 2.9-2.5.1 0 .1 0 .2.1.7-1 2.3-1.6 3.5-2.1.3-.1.7-.2.8-.3.1-.1.3-.3.1-1.1z"
      />
      <path
        fill="#19325c"
        d="M73.9 441.6c-.1.3-.1.7 0 .8.1 0 .3-.2.5-.6.1-.3.1-.7 0-.8-.1-.1-.4.3-.5.6zm.2-1.1c-.1 0-.1-.1-.1-.2s0-.2.2-.3c.5-.1.9 0 1.4.1.1 0 .2.2.1.3 0 .1-.2.2-.3.1-.5-.1-.7-.2-1-.1-.1.1-.3.1-.3.1z"
      />
      <path
        fill="#29427b"
        d="M249.9 428.7c-.6 0-1 .5-1 1v.5h-2.6c-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.3 2.3 2.3h2.6v.5c0 .6.5 1 1 1 .6 0 1-.5 1-1v-5.6c-.1-.6-.5-1-1-1zm0 11.4c-.6 0-1 .5-1 1v.5h-2.6c-1.3 0-2.3 1-2.3 2.3 0 1.3 1 2.3 2.3 2.3h2.6v.5c0 .6.5 1 1 1 .6 0 1-.5 1-1v-5.6c-.1-.5-.5-1-1-1z"
      />
      <path
        fill="#a8aeff"
        d="M213.9 519.6h-82.1v-2.3h82.1c12.9 0 23.3-10.5 23.3-23.4v-48.4c0-1.5 1.3-2.7 2.7-2.7h3.8v2.3H240c-.2 0-.5.2-.5.5V494c.1 14.1-11.4 25.6-25.6 25.6z"
      />
      <path
        fill="#a8aeff"
        d="M213.9 508.2h-82.1v-2.3h82.1c6.5 0 11.9-5.4 11.9-11.9v-48.4c0-7.8 6.4-14.2 14.2-14.2h3.8v2.3H240c-6.5 0-11.9 5.4-11.9 11.9V494c0 7.8-6.3 14.2-14.2 14.2z"
      />
      <path
        fill="#a8aeff"
        d="M131.8 521c0 .6.5 1 1 1 .6 0 1-.5 1-1v-17.1c0-.6-.5-1-1-1-.6 0-1 .5-1 1V521z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={592.839}
          x2={592.839}
          y1={56.02}
          y2={157.741}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCD63A" />
          <stop offset={1} stopColor="#FFF3C1" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export { Illustration };
