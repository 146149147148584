import { CheckIcon } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import * as React from 'react';

import { ContactForm } from '../components/contact-form';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Buildings } from '../icons/buildings';
import { Illustration } from '../icons/illustration';
import { Logo } from '../icons/logo';
import { ConsultationForm } from '../components/consultation-form';

function IndexPage(): JSX.Element {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Hero />
        <FreeConsultation />
        {/* <GetStarted /> */}
        <Pricing />
        <YourOptions />
        <GenerateSales />
        <TheWebsiteFactory />
        <ContactForm />
      </Layout>
    </>
  );
}

function Hero(): JSX.Element {
  return (
    <div className="px-4 py-12 lg:py-24 sm:px-6 lg:px-8">
      <Link to="/" className="block w-full max-w-sm p-4 mx-auto rounded-lg">
        <span className="sr-only">The Website Factory</span>
        <Logo aria-hidden />
      </Link>
    </div>
  );
}

function GetStarted(): JSX.Element {
  return (
    <Link
      to="/#contact"
      className="fixed bottom-0 top-auto z-10 px-4 py-2 text-lg font-black text-white rounded-t-lg lg:rounded-tl-none lg:px-2 lg:py-4 right-4 sm:right-6 lg:right-auto lg:bottom-auto lg:left-0 lg:-translate-y-1/2 lg:rounded-r-xl bg-pink lg:top-1/2 transform-gpu font-display lg:writing-mode-vertical-lr focus:ring-offset-cream focus:ring-offset-2 focus:ring-2 focus:ring-pink"
    >
      Get Started
    </Link>
  );
}

function Pricing(): JSX.Element {
  return (
    <div id="pricing" className="relative px-4 mt-8 sm:px-6 lg:px-8">
      <div aria-hidden className="absolute inset-y-0 left-0 w-1/2 bg-cream" />
      <div className="relative block px-4 py-12 rounded-2xl sm:px-6 lg:px-8 bg-cream">
        <div className="max-w-4xl mx-auto">
          <h2>
            <span className="sr-only">Websites starting from $897*</span>
            <div
              aria-hidden
              className="font-black font-display sm:space-x-8 sm:flex lg:space-x-24"
            >
              <div className="text-4xl leading-10">
                Website <br />
                setup <br />
                starting <br />
                from
              </div>
              <div className="flex items-start leading-[6rem] text-pink text-9xl md:leading-[9rem] md:text-[12rem]">
                <span className="text-5xl">$</span>
                897
                <span className="text-7xl">*</span>
              </div>
            </div>
          </h2>
          <div className="mt-12">
            <div className="md:flex md:space-x-8">
              <h3 className="text-4xl font-bold font-display">
                <div className="text-pink">Standard 7 Page </div>
                <div>website includes </div>
                <div>the following pages: </div>
              </h3>
              <div className="w-full max-w-sm mt-6 prose prose-teal md:mt-0">
                <ul className="font-bold md:col-count-2">
                  <li className="!mt-0">Landing Page</li>
                  <li>About Page</li>
                  <li>Services Page</li>
                  <li>Blog Page</li>
                  <li>Contact Page</li>
                  <li>404 Page</li>
                  <li>Success Page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function YourOptions(): JSX.Element {
  return (
    <div
      id="your_options"
      className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8"
    >
      <div aria-hidden className="absolute inset-y-0 right-0 w-1/2 bg-pink" />
      <div className="relative block px-4 py-12 rounded-2xl bg-pink text-cream sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-black font-display">Your Options</h2>
          <div className="flex flex-wrap mt-6 -my-4 font-bold sm:-mx-4">
            <div className="w-full py-4 space-y-2 border-t-2 border-cream sm:py-0 sm:px-4 sm:w-1/2 sm:border-t-0 sm:border-r-2 md:w-1/3 lg:w-1/5 first:border-t-0">
              <p>Website Setup from $897</p>
              <p>
                <small>Excluding GST</small>
              </p>
            </div>
            <div className="w-full py-4 space-y-2 border-t-2 border-cream sm:py-0 sm:px-4 sm:w-1/2 sm:border-t-0 md:w-1/3 md:border-r-2 lg:w-1/5 first:border-t-0">
              <p>Website Licensing &amp; Hosting from $20 a month!</p>
              <p>That’s 1 coffee a week</p>
            </div>
            <div className="w-full py-4 space-y-2 border-t-2 border-cream sm:py-0 sm:px-4 sm:w-1/2 sm:border-t-0 sm:border-r-2 md:w-1/3 md:border-r-0 lg:w-1/5 lg:border-r-2 first:border-t-0">
              <p>Need Extra Pages?</p>
              <p>They start from $49 per page!</p>
            </div>
            <div className="w-full py-4 space-y-2 border-t-2 border-cream sm:py-0 sm:px-4 sm:w-1/2 sm:border-t-0 sm:border-r-0 md:w-1/3 md:border-r-2 lg:w-1/5 first:border-t-0">
              <p>Entire website copy from $299!</p>
              <p>
                <small>Conditions apply*</small>
              </p>
            </div>
            <div className="w-full py-4 space-y-2 border-t-2 border-cream sm:py-0 sm:px-4 sm:w-1/2 sm:border-t-0 sm:border-r-2 md:w-1/3 lg:w-1/5 lg:border-r-0 first:border-t-0">
              <p>
                Talk to our specialist team about our{' '}
                <span className="inline-block">E-Commerce</span> packages!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface CheckProps {
  children: React.ReactNode;
}

function Check({ children }: CheckProps): JSX.Element {
  return (
    <li className="!pl-0 no-before flex items-start">
      <div className="relative flex items-start !my-0">
        <div className="inline-flex items-center">
          &#8203;
          <CheckIcon className="w-5 h-5 text-yellow" />
        </div>
        <div className="inline-flex items-center ml-3 bg-gradient-to-b from-transparent via-teal to-transparent">
          {children}
        </div>
      </div>
    </li>
  );
}

function GenerateSales(): JSX.Element {
  return (
    <div
      id="generate_sales"
      className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8"
    >
      <div aria-hidden className="absolute inset-y-0 left-0 w-1/2 bg-teal" />
      <div className="relative block px-4 py-12 rounded-2xl sm:px-6 lg:px-8 bg-teal text-cream">
        <Buildings
          aria-haspopup
          className="absolute inset-x-0 bottom-0 -ml-4 sm:-ml-6 lg:-ml-8"
        />
        <Illustration
          aria-haspopup
          className="absolute inset-x-0 w-2/3 max-w-4xl ml-auto translate-x-4 bottom-8 md:w-1/2 transform-gpu"
        />
        <div className="relative max-w-4xl mx-auto pb-36">
          <h2 className="text-6xl font-black font-display">
            <div>Our websites are built to</div>
            <div className="text-yellow">generate you sales!</div>
          </h2>
          <h3 className="mt-8 text-4xl font-black font-display">
            <div>Find out how we can get your business </div>
            <div>on the 1st page of Google</div>
          </h3>
          <div className="max-w-2xl mt-8 font-bold prose prose-dark text-cream">
            <p>What we promise:</p>
            <ul>
              <Check>Australian Business</Check>
              <Check>Designed For Lead Generation</Check>
              <Check>Quality Guaranteed</Check>
              <Check>SEO and Digital Marketing Ready</Check>
              <Check>Quick and EASY setup</Check>
              <Check>Crazy Low Hosting Fees</Check>
              <Check>Mobile, Tablet and Desktop Responsive</Check>
            </ul>
          </div>
          <p className="mt-8">
            <Link
              to="/#contact"
              className="inline-flex p-4 text-lg font-semibold uppercase sm:mt-0 rounded-2xl bg-yellow text-teal"
            >
              Get sales now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

function TheWebsiteFactory(): JSX.Element {
  return (
    <div
      id="what_we_do"
      className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8"
    >
      <div aria-hidden className="absolute inset-y-0 right-0 w-1/2 bg-pink" />
      <div className="relative block px-4 py-12 rounded-2xl sm:px-6 lg:px-8 bg-pink text-cream">
        <div className="max-w-4xl mx-auto">
          <h2>
            <div className="text-6xl font-black font-display">
              The Website Factory
            </div>
            <div className="max-w-2xl text-2xl font-bold">
              builds websites using our custom-developed template, created by
              the team at Phiranno Designs
            </div>
          </h2>
          <div className="max-w-2xl mt-5 prose prose-dark text-cream">
            <p>
              We have built a whole range of templates that will suit your
              company, and if we haven’t built one for your niche, then you can
              guarantee it’s in our development pipeline! Our templates look
              great, are easy to use, and are easily updatable with content.
            </p>
            <p>
              We’ve put in all the hard work pe-building everything out, so you
              get all the benefits of a low-cost website set and with low
              ongoing fees to suit your needs.
            </p>
            <p>
              Our team has been building websites for years! So we understand
              what makes a great website and what doesn’t, you can trust our
              templates to do the trick for you! Inquire with the form below,
              and we will get back to you and discuss your needs!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function FreeConsultation(): JSX.Element {
  return (
    <div className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8">
      <div aria-hidden className="absolute inset-y-0 right-0 w-1/2 bg-teal" />
      <div className="relative block px-4 py-12 rounded-2xl sm:px-6 lg:px-8 bg-teal text-cream">
        <div className="max-w-4xl mx-auto">
          <h2>
            <div className="text-4xl font-black sm:text-5xl font-display">
              Get your{' '}
              <span className="uppercase text-yellow">free consultation</span>{' '}
              now
            </div>
          </h2>
          <div className="max-w-4xl mt-8">
            <ConsultationForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
