import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Input } from './form-elements/input';
import { NetlifyForm } from './form-elements/netlify-form';
import { Textarea } from './form-elements/textarea';

interface FormData {
  full_name: string;
  email_address: string;
  contact_number: string;
  message: string;
}

function ConsultationForm(): JSX.Element {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur', reValidateMode: 'onChange' });

  return (
    <div className="relative">
      <div className="relative block">
        <div className="w-full mx-auto">
          <NetlifyForm handleSubmit={handleSubmit} name="consultation_form">
            <div className="grid w-full gap-8">
              <Input
                label="Full Name"
                autoComplete="name"
                {...register('full_name', { required: true })}
                errors={errors}
                onDark={true}
              />
              <Input
                label="Email Address"
                autoComplete="email"
                {...register('email_address', { required: true })}
                errors={errors}
                onDark={true}
              />
              <Input
                label="Phone"
                type="tel"
                autoComplete="tel"
                {...register('contact_number', { required: true })}
                errors={errors}
                onDark={true}
              />
              <Textarea
                label="Message"
                rows={3}
                {...register('message', { required: true })}
                errors={errors}
                onDark={true}
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="flex self-end px-8 py-2 mt-8 text-lg font-semibold uppercase rounded-xl bg-yellow text-teal"
              >
                Inquire now
              </button>
            </div>
          </NetlifyForm>
        </div>
      </div>
    </div>
  );
}

export { ConsultationForm };
